var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
// @mui
import { Button, IconButton, MenuItem, TableCell, TableRow, Typography, } from '@mui/material';
import { fDate } from '../../../utils';
import Iconify from '../../../components/iconify';
import MenuPopover from '../../../components/menu-popover';
import ConfirmDialog from '../../../components/confirm-dialog';
import usePermission from '../../../hooks/usePermission';
import { HOST_API_PUBLIC } from '../../../config-global';
export default function BookStockTableRow(_a) {
    var _b, _c;
    var row = _a.row, selected = _a.selected, onEditRow = _a.onEditRow, onSelectRow = _a.onSelectRow, onDeleteRow = _a.onDeleteRow, onViewRow = _a.onViewRow;
    var image = row.image, office = row.office, book = row.book, quantity = row.quantity, description = row.description, price = row.price, createdAt = row.createdAt;
    var _d = useState(false), openConfirm = _d[0], setOpenConfirm = _d[1];
    var _e = useState(null), openPopover = _e[0], setOpenPopover = _e[1];
    var handleOpenConfirm = function () {
        setOpenConfirm(true);
    };
    var handleCloseConfirm = function () {
        setOpenConfirm(false);
    };
    var handleOpenPopover = function (event) {
        setOpenPopover(event.currentTarget);
    };
    var handleClosePopover = function () {
        setOpenPopover(null);
    };
    //   const [openConfirm, setOpenConfirm] = useState(false);
    //   const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
    var _f = usePermission(), isSuperAdmin = _f.isSuperAdmin, hasPermissionViewBookStock = _f.hasPermissionViewBookStock, hasPermissionEditBookStock = _f.hasPermissionEditBookStock, hasPermissionDeleteBookStock = _f.hasPermissionDeleteBookStock;
    // check current user permissions
    var isAllowedToViewBookStock = isSuperAdmin || hasPermissionViewBookStock;
    var isAllowedToEditBookStock = isSuperAdmin || hasPermissionEditBookStock;
    var isAllowedToDeleteBookStock = isSuperAdmin || hasPermissionDeleteBookStock;
    //   const handleOpenConfirm = () => {
    //     setOpenConfirm(true);
    //   };
    //   const handleCloseConfirm = () => {
    //     setOpenConfirm(false);
    //   };
    //   const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    //     setOpenPopover(event.currentTarget);
    //   };
    //   const handleClosePopover = () => {
    //     setOpenPopover(null);
    //   };
    //   const color = getColor(amount);
    return (_jsxs(_Fragment, { children: [_jsxs(TableRow, __assign({ hover: true, selected: selected }, { children: [_jsx(TableCell, { children: (book === null || book === void 0 ? void 0 : book.name) || '_____' }), _jsx(TableCell, { children: image ? (_jsx("div", __assign({ style: { maxWidth: 300, height: 100 } }, { children: _jsx("img", { src: "".concat(HOST_API_PUBLIC, "/uploads/").concat(image), alt: (book === null || book === void 0 ? void 0 : book.name) || 'book', style: {
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    borderRadius: '12px',
                                }, crossOrigin: "anonymous" }) }))) : ('__________') }), _jsx(TableCell, { children: ((_b = book === null || book === void 0 ? void 0 : book.level) === null || _b === void 0 ? void 0 : _b.name) || '__________' }), _jsx(TableCell, { children: ((_c = book === null || book === void 0 ? void 0 : book.section) === null || _c === void 0 ? void 0 : _c.name) || '__________' }), _jsx(TableCell, { children: (office === null || office === void 0 ? void 0 : office.name) || '__________' }), _jsx(TableCell, { children: _jsx(Typography, __assign({ variant: "subtitle2", noWrap: true, sx: {
                                fontSize: '0.875rem',
                                background: 'red',
                                borderRadius: '.6rem',
                                textAlign: 'center',
                                width: 'fit-content',
                                padding: '0.5rem 1rem',
                                color: 'white',
                            } }, { children: quantity })) }), _jsx(TableCell, __assign({ align: "center" }, { children: fDate(createdAt) })), _jsx(TableCell, __assign({ align: "center" }, { children: _jsx(IconButton, __assign({ color: openPopover ? 'inherit' : 'default', onClick: handleOpenPopover }, { children: _jsx(Iconify, { icon: "eva:more-vertical-fill" }) })) }))] })), _jsxs(MenuPopover, __assign({ open: openPopover, onClose: handleClosePopover, arrow: "right-top", sx: { width: 140 } }, { children: [isAllowedToViewBookStock && (_jsxs(MenuItem, __assign({ onClick: function () {
                            onViewRow();
                            handleClosePopover();
                        } }, { children: [_jsx(Iconify, { icon: "carbon:view-filled" }), "View"] }))), isAllowedToEditBookStock && (_jsxs(MenuItem, __assign({ onClick: function () {
                            onEditRow();
                            handleClosePopover();
                        } }, { children: [_jsx(Iconify, { icon: "eva:edit-fill" }), "Edit"] }))), isAllowedToDeleteBookStock && (_jsxs(MenuItem, __assign({ onClick: function () {
                            handleOpenConfirm();
                            handleClosePopover();
                        }, sx: { color: 'error.main' } }, { children: [_jsx(Iconify, { icon: "eva:trash-2-outline" }), "Delete"] })))] })), _jsx(ConfirmDialog, { open: openConfirm, onClose: handleCloseConfirm, title: "Delete", content: "Are you sure want to delete?", action: _jsx(Button, __assign({ variant: "contained", color: "error", onClick: function () {
                        onDeleteRow();
                        handleCloseConfirm();
                    } }, { children: "Delete" })) })] }));
}
